<template>
  <div v-if="customer" class="menu">
    <div class="menu-main">
      <!-- <div class="menu__inner">
        <div class="menu-header">
          <div class="menu-header-icon">
            <img :src="`/assets/img/flag/${mileStageKey}.svg`" alt="" />
          </div>
          <div class="menu-header-info">
            <div class="menu-header-info__rank">
              <span>{{ mileStageLabel }}</span>
              <a :href="$customUrlScheme.page('detail', 'url=/stage')">
                <Icon name="helpCircle" />
              </a>
            </div>
            <div class="menu-header-info-mile">
              <div class="menu-header-info-mile__value">
                <span v-text="mile" />マイル
              </div>
              <Button
                :href="
                  $customUrlScheme.page(
                    'standalone',
                    'url=/mypage/mile/history'
                  )
                "
                style-type="tertiary"
                squared
                action
                flat>
                マイル履歴
              </Button>
            </div>
          </div>
        </div>
      </div> -->

      <div class="menu__inner">
        <div class="menu-mypage">
          <ul>
            <!-- <li
              class="menu-mypage-item"
              :class="{
                'menu-mypage-item--noticed': hasUnreadPresent
              }">
              <a
                :href="$customUrlScheme.page('standalone', 'url=/mypage/gift')"
                class="menu-mypage-item__inner">
                <Icon name="present" />
                <span>プレゼントBOX</span>
              </a>
            </li> -->
            <li
              class="menu-mypage-item"
              :class="{
                'menu-mypage-item--noticed': hasUnreadNotice
              }">
              <a
                :href="$customUrlScheme.page('standalone', 'url=/notice')"
                class="menu-mypage-item__inner">
                <Icon name="bell" />
                <span>お知らせ</span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <!-- <div class="menu__inner">
        <div class="menu-onlinestore">
          <h3 class="c-lead3 c-lead3--grey02">オンラインストア</h3>
          <ul class="menu-list">
            <li>
              <a :href="$customUrlScheme.page('ec')">
                UCC公式オンラインストア
              </a>
            </li>
            <li>
              <a
                :href="
                  $customUrlScheme.page('standalone', 'url=/link/onlinestore')
                ">
                オンラインストア連携
              </a>
              <div v-if="ecLink.done" class="menu-check-circle-bordered">
                <Icon name="check" />
              </div>
              <Chip v-else-if="ecLink.mile" type="primary">
                {{ ecLink.mile }}
                <span class="c-chip__unit">マイル</span>
              </Chip>
            </li>
          </ul>
        </div>
      </div> -->

      <div class="menu__inner">
        <div class="menu-account">
          <h3 class="c-lead3 c-lead3--grey02">アカウント</h3>
          <ul class="menu-list">
            <!-- <li>
              <a :href="$customUrlScheme.page('standalone', 'url=/link/line')">
                LINE連携
              </a>
              <div v-if="lineLink.done" class="menu-check-circle-bordered">
                <Icon name="check" />
              </div>
              <Chip v-else-if="lineLink.mile" type="primary">
                {{ lineLink.mile }}
                <span class="c-chip__unit">マイル</span>
              </Chip>
            </li> -->
            <li>
              <a :href="$customUrlScheme.page('standalone', 'url=/takeover')">
                アカウント引継
              </a>
            </li>
            <li>
              <a href="javascript:void(0);" @click="openNotificationSettings">
                プッシュ通知設定
              </a>
              <Chip v-if="isAllowedNotification" type="primary">
                受け取る
              </Chip>
              <Chip v-else type="black">受け取らない</Chip>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="menu__inner menu__inner--sub">
      <div class="menu-sub">
        <div class="menu-info">
          <h3 class="menu-sub-title">インフォメーション</h3>
          <ul class="menu-info__list">
            <li>
              <a :href="$customUrlScheme.page('standalone', 'url=/contact')">
                お問い合わせ
              </a>
            </li>
            <li>
              <a :href="$customUrlScheme.page('standalone', 'url=/terms')">
                利用規約
              </a>
            </li>
            <li>
              <a :href="$customUrlScheme.page('standalone', 'url=/help')">
                ヘルプ
              </a>
            </li>
            <li>
              <a :href="$customUrlScheme.page('standalone', 'url=/version')">
                バージョン情報
              </a>
            </li>
            <li>
              <a :href="$customUrlScheme.page('onboarding')">
                アプリについて
              </a>
            </li>
          </ul>

          <div class="menu-sub-id">お客様番号:{{ customer.id }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  onUnmounted
} from '@vue/composition-api';
import { SystemDialogMessage, MileStageType } from '@/constants/enums';
import { useNativeConnection } from '@/composables/useNativeConnection';
const { getNotificationStatus, openNotificationSettings } =
  useNativeConnection();

export default defineComponent({
  setup: (props, context) => {
    const customer = ref(null);
    const notificationStatus = ref(null);

    const mileStageId = computed(() =>
      MileStageType.getMileStageTypeKeyById(
        customer.value?.mileage_detail?.rank_id
      )
    );
    const mileStageKey = computed(() =>
      MileStageType.getTypeName(mileStageId.value)
        .replace(' ', '')
        .toLowerCase()
    );
    const mileStageLabel = computed(() =>
      MileStageType.getTypeName(mileStageId.value, 'ja')
    );

    const mile = computed(() => customer.value?.mileage_detail?.total || 0);

    /**
     * @returns {{ done: boolean; mile: number; }}
     */
    const lineLink = computed(() => {
      return {
        done: Boolean(customer.value?.line?.is_alignment) || false,
        mile: customer.value?.line?.is_past_alignment
          ? 0
          : customer.value?.line?.mileage_amount || 0
      };
    });

    /**
     * @returns {{ done: boolean; mile: number; }}
     */
    const ecLink = computed(() => {
      return {
        done: Boolean(customer.value?.ec?.is_alignment) || false,
        mile: customer.value?.ec?.is_past_alignment
          ? 0
          : customer.value?.ec?.mileage_amount || 0
      };
    });

    /**
     * プッシュ通知の許可フラグ
     * @returns {boolean}
     */
    const isAllowedNotification = computed(
      () => notificationStatus.value?.is_allowed || false
    );

    /**
     * プレゼントBOXに未読要素があるかどうか
     *
     * @returns {boolean}
     */
    const hasUnreadPresent = computed(() => customer.value?.is_present_unread);

    /**
     * お知らせに未読要素があるかどうか
     *
     * @returns {boolean}
     */
    const hasUnreadNotice = computed(() => customer.value?.is_notice_unread);

    /**
     * NOTE:
     * `window.view.getCustomer()` がネイティブ側から呼び出される想定なので、
     * 関数名変更する際はネイティブ側にも連携が必要
     */
    const getCustomer = async () => {
      try {
        context.root.$_loading_start();

        const { data } = await context.root
          .$repositories('customer')
          .getCustomer();
        customer.value = data;
        notificationStatus.value = await getNotificationStatus();
      } catch {
        window.location.href = context.root.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      } finally {
        context.root.$_loading_stop();
      }
    };

    // NOTE: ネイティブ側からインスタンスにアクセスするためにwindowにバインドしておく
    window.view = { getCustomer };
    getCustomer();

    onMounted(async () => {
      notificationStatus.value = await getNotificationStatus();
    });

    onUnmounted(() => {
      window.view = undefined;
    });

    return {
      customer,
      mileStageKey,
      mileStageLabel,
      mile,
      lineLink,
      ecLink,
      isAllowedNotification,
      openNotificationSettings,
      hasUnreadPresent,
      hasUnreadNotice
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.menu {
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: 100svh;

  a:not(.c-button) {
    font-size: 1.4rem;
    line-height: 1.5;
    font-weight: bold;
    text-decoration: none;
  }

  &__inner {
    padding: 2rem 2.4rem;

    &:last-child {
      padding-bottom: 3.2rem;
    }

    :last-child {
      margin-bottom: 0;
    }

    &:not(:last-child) {
      border-bottom: 1px solid variables.$grey04;
    }

    &--sub {
      padding: 1.6rem 2.4rem;
    }
  }
}

.menu-main {
  background-color: variables.$white01;
  box-shadow: variables.$elevationsXs;

  a:not(.c-button) {
    color: variables.$black01;
    font-size: 14px;
    line-height: 1.5;
    font-weight: bold;
  }
}

.menu-sub {
  color: variables.$grey01;

  a:not(.c-button) {
    color: variables.$grey01;
    font-size: 13px;
    font-weight: normal;
    display: inline-block;
    width: 100%;
  }
}

.menu-sub-title {
  margin-bottom: 8px;
  font-size: 13px;
  color: variables.$grey02;
}

.menu-sub-id {
  text-align: right;
  font-size: 11px;
  line-height: 1.5;
  color: variables.$grey02;
}

.menu-header {
  display: flex;
}

.menu-header-icon {
  display: grid;
  place-items: center;
  width: 5.6rem;
  height: 5.6rem;
  background: variables.$grey04;
  border-radius: 50%;

  > img {
    width: 3.8rem;
    height: 3.8rem;
  }
}

.menu-header-info {
  flex: 1;
  margin-left: 1.3rem;

  &__rank {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.25;
    margin-right: 0.8rem;
    margin-bottom: 0.3rem;

    > a {
      display: grid;
      place-items: center;
      line-height: 1;
      padding: 0 0.8rem;
    }
  }
}

.menu-header-info-mile {
  display: flex;
  align-items: flex-end;
  margin-bottom: 0.4rem;

  &__value {
    flex: 1;
    font-size: 2rem;
    font-weight: bold;
    color: variables.$secondary01;
    line-height: 1;
    word-break: keep-all;

    > span {
      font-size: 3.2rem;
      margin-right: 0.8rem;
      word-break: break-all;
    }
  }

  .c-button {
    padding: 0.4rem 0.9rem;
    height: 3rem;
    font-size: 1.1rem;
  }
}

.menu-mypage {
  a:not(.c-button) {
    font-size: 1.6rem;
  }
}

.menu-mypage-item {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 1.6rem;
  }

  &__inner {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.25;

    .c-icon {
      width: 2.4rem;
      height: 2.4rem;
      margin-right: 1.9rem;
    }
  }

  &--noticed {
    &::before {
      z-index: 1;
      position: absolute;
      top: -2px;
      left: -4px;
      content: '';
      width: 6px;
      height: 6px;
      background: variables.$primary01;
      border-radius: 10em;
    }
  }
}

.menu-onlinestore {
  .c-lead3 {
    margin-bottom: 1.2rem;
  }
}

.menu-account {
  .c-lead3 {
    margin-bottom: 1.2rem;
  }
}

.menu-list {
  > li {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > :first-child {
      flex: 1;
    }

    &:not(:last-child) {
      margin-bottom: 1.2rem;
    }
  }
}

.menu-info {
  .c-lead3 {
    margin-bottom: 1rem;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.8rem;
    margin-bottom: 1.6rem;
    line-height: 1;
  }
}

.menu-check-circle-bordered {
  display: grid;
  place-items: center;
  width: 24px;
  height: 24px;
  background: variables.$grey03;
  border-radius: 999px;

  .c-icon {
    width: 14px;
    height: 14px;

    g {
      fill: variables.$white02;
    }
  }
}
</style>
