var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.customer
    ? _c("div", { staticClass: "menu" }, [
        _c("div", { staticClass: "menu-main" }, [
          _c("div", { staticClass: "menu__inner" }, [
            _c("div", { staticClass: "menu-mypage" }, [
              _c("ul", [
                _c(
                  "li",
                  {
                    staticClass: "menu-mypage-item",
                    class: {
                      "menu-mypage-item--noticed": _vm.hasUnreadNotice,
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "menu-mypage-item__inner",
                        attrs: {
                          href: _vm.$customUrlScheme.page(
                            "standalone",
                            "url=/notice"
                          ),
                        },
                      },
                      [
                        _c("Icon", { attrs: { name: "bell" } }),
                        _c("span", [_vm._v("お知らせ")]),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "menu__inner" }, [
            _c("div", { staticClass: "menu-account" }, [
              _c("h3", { staticClass: "c-lead3 c-lead3--grey02" }, [
                _vm._v("アカウント"),
              ]),
              _c("ul", { staticClass: "menu-list" }, [
                _c("li", [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.$customUrlScheme.page(
                          "standalone",
                          "url=/takeover"
                        ),
                      },
                    },
                    [_vm._v(" アカウント引継 ")]
                  ),
                ]),
                _c(
                  "li",
                  [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0);" },
                        on: { click: _vm.openNotificationSettings },
                      },
                      [_vm._v(" プッシュ通知設定 ")]
                    ),
                    _vm.isAllowedNotification
                      ? _c("Chip", { attrs: { type: "primary" } }, [
                          _vm._v(" 受け取る "),
                        ])
                      : _c("Chip", { attrs: { type: "black" } }, [
                          _vm._v("受け取らない"),
                        ]),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "menu__inner menu__inner--sub" }, [
          _c("div", { staticClass: "menu-sub" }, [
            _c("div", { staticClass: "menu-info" }, [
              _c("h3", { staticClass: "menu-sub-title" }, [
                _vm._v("インフォメーション"),
              ]),
              _c("ul", { staticClass: "menu-info__list" }, [
                _c("li", [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.$customUrlScheme.page(
                          "standalone",
                          "url=/contact"
                        ),
                      },
                    },
                    [_vm._v(" お問い合わせ ")]
                  ),
                ]),
                _c("li", [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.$customUrlScheme.page(
                          "standalone",
                          "url=/terms"
                        ),
                      },
                    },
                    [_vm._v(" 利用規約 ")]
                  ),
                ]),
                _c("li", [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.$customUrlScheme.page(
                          "standalone",
                          "url=/help"
                        ),
                      },
                    },
                    [_vm._v(" ヘルプ ")]
                  ),
                ]),
                _c("li", [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.$customUrlScheme.page(
                          "standalone",
                          "url=/version"
                        ),
                      },
                    },
                    [_vm._v(" バージョン情報 ")]
                  ),
                ]),
                _c("li", [
                  _c(
                    "a",
                    {
                      attrs: { href: _vm.$customUrlScheme.page("onboarding") },
                    },
                    [_vm._v(" アプリについて ")]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "menu-sub-id" }, [
                _vm._v("お客様番号:" + _vm._s(_vm.customer.id)),
              ]),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }